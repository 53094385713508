import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WarningOutline } from '@ant-design/icons-angular/icons';
import { LoadingService } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ToolsService } from '../../services/tools.service';
import { environment } from '@env/environment'

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styles: []
})
export class AuthLoginComponent implements OnInit {
  viewRef: 'commonError';
  errorMsg: string;
  isLoading = false;
  UUID = '';
  WarningOutline = WarningOutline;

  constructor(
    private loadingSrv: LoadingService,
    private http: HttpClient,
    private msg: NzMessageService,
    private router: Router,
    private modalSrv: NzModalService,
    private toolsService: ToolsService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit() {
    // 显示加载动画
    this.loadingSrv.open({ type: 'icon' });
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const token = params.get('token');

    if(!token){
      // 跳转山东通平台免登页面
      this.router.navigateByUrl('/shandongtonglogin?code=' + code, {
        replaceUrl: true,
        state: {
          
        },
      });
      return
    }

    // 缺少 code 或 token 参数则中断流程
    if (!code || !token) {
      this.viewRef = 'commonError';
      this.errorMsg = '参数错误';
      this.loadingSrv.close();
      return
    }

    this.UUID = this.getUUID()
    this.http.post('/CNIC_M/SysManageV1/loginOAuth4Aolian3', {
      "cLoginUuid" : this.UUID,
      "cTerminalType" : "pc",
      "code" : code,
      "token" : token,
      "al3Url" : environment.al3Url,
    }).subscribe((res: any) => {
      if (res.code === 1) {
        const user = res.data.loginMap;

        // 判定用戶是否其他PC端登录
        if(user.bAlreadyLogin){
          this.modalSrv.confirm({
            nzTitle: '系统提示',
            nzContent: '当前账户已在别处登录，是否强制登录?',
            nzWrapClassName: 'vertical-center-modal',
            nzOnOk: () => {
              this.modalSrv.closeAll();
              this.forcedToLogin(user.sys_User, (fuser: any)=>{
                this.loginInfoExe(fuser, fuser.account.cCNICSigID);
              })
            }
          });
        } else {
          this.loginInfoExe(user, res.data.cCNICSigID);
        }
      } else {
        this.viewRef = 'commonError';
        this.errorMsg = res.msg;
      }
    }, () => {
      this.viewRef = 'commonError';
      this.errorMsg = '参数错误';
    }, () => {
      this.loadingSrv.close();
    });
  }

  // 生成UUID
  getUUID() {
    return (
      this.randomStr() +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      this.randomStr() +
      this.randomStr()
    );
  }
  // 创建随机字符串
  randomStr() {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  // 强制登录操作
  forcedToLogin(param: any, nextFun){
    this.isLoading = true;

    this.http.post("/cnic-auth/ZwzqAction/getDataNoLogin",{
      cUserUUID : param.cUserUUID,
      cUserName : param.cUserName,
      cLoginDate : this.toolsService.getNowFormatDateYMD(),
      cCNICSigID : param.cCNICSigID,
      cUUID : this.UUID,
      iType : 1,
      isLogin : true
    })
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.isLoading = true;
            if(nextFun){
              nextFun(res.data);
            }
          }
          else {
            this.message.error(res.msg);
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.message.error('连接服务器失败...');
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  // 处理用户登录信息
  loginInfoExe(res: any, cCNICSigID){
    // 处理登录信息
    this.toolsService.loginInfoHandle(res, cCNICSigID,
      () => {
        // 保存UUID
        window.sessionStorage.setItem('SID', this.UUID);
        // 保存登录类
        window.sessionStorage.setItem('loginType', 'gov');
        // 初始化FBI
        this.toolsService.initFBI(res);

        // 跳转工作台首页
        this.router.navigateByUrl('/', {
          replaceUrl: true,
          state: {

          },
        });
      });
  }
}
