import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TitleService } from '@delon/theme';
import { CacheService } from '@delon/cache';

import { NzIconService } from 'ng-zorro-antd/icon';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { ICONS } from '../../../style-icons';
// import { Router } from '@angular/router';
/**
 * 应用启动 初始化基础数据
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    private cache: CacheService, // private router: Router
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }

  // 自定义数据初始化
  loadSetInfo(resolve: any, reject: any) {
    // 移除系统设置的缓存
    this.cache.remove('zw_setInfo');

    if (document.documentURI.length >= 11 && document.documentURI.slice(-11).substring(0, 4) === 'code') {
      window.sessionStorage.setItem('zw_code', document.documentURI.slice(-6));
      this.httpClient
        .post('/cnic-auth/ZwzqAction/getSystemManagement2', { url: document.documentURI.slice(-6) })
        .subscribe(
          (res: any) => {
            if (res.code === 1) {
              // 存储系统设置信息
              const setInfo: any = {
                default: false,
                title: res.data.sys_SystemManagement.cSystemTitle,
                logo: res.data.sys_SystemManagement.cSystemLOGOmin,
                loginBackground: res.data.sys_SystemManagement.cLoginBackgroundImage,
              };
              this.cache.set('zw_setInfo', JSON.stringify(setInfo), { type: 'm' });

              resolve(null);
            } else {
              this.setDefaultSysSet(resolve);
            }
          },
          (error: any) => {
            this.setDefaultSysSet(resolve);
          },
          () => {
            this.titleService.separator = '';
            this.titleService.suffix = '';
          },
        );
    } else {
      this.httpClient.post('/cnic-auth/ZwzqAction/getSystemManagement2', { url: document.domain }).subscribe(
        (res: any) => {
          if (res.code === 1) {
            // 存储系统设置信息
            const setInfo: any = {
              default: false,
              title: res.data.sys_SystemManagement.cSystemTitle,
              logo: res.data.sys_SystemManagement.cSystemLOGOmin,
              loginBackground: res.data.sys_SystemManagement.cLoginBackgroundImage,
            };
            this.cache.set('zw_setInfo', JSON.stringify(setInfo), { type: 'm' });

            resolve(null);
          } else {
            this.setDefaultSysSet(resolve);
          }
        },
        (error: any) => {
          this.setDefaultSysSet(resolve);
        },
        () => {
          this.titleService.separator = '';
          this.titleService.suffix = '';
        },
      );
    }
  }
  // 设置系统默认值
  setDefaultSysSet(resolve: any) {
    this.httpClient.get('assets/project-info.json').subscribe(
      (res: any) => {
        // 存储系统设置信息
        const setInfo: any = {
          default: true,
          title: res.sysInfo.title,
          logo: res.sysInfo.logo,
          loginBackground: res.sysInfo.loginBackground,
        };
        this.cache.set('zw_setInfo', JSON.stringify(setInfo), { type: 'm' });
      },
      () => {},
      () => {
        resolve(null);
      },
    );
  }

  // 加载初始化数据设置
  load(): Promise<any> {

    return new Promise((resolve, reject) => {
      // 自定义数据加载
      this.loadSetInfo(resolve, reject);
    });
  }
}
