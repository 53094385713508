import { Component } from '@angular/core';
import { YhFlowService } from 'src/app/services/yhFlow.service';
import { OrgChooseService } from '../../../../services/orgchoose.service';

declare var $: any;

@Component({
  selector: 'header-org',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm"
      nz-dropdown
      nzPlacement="bottomRight"
      [nzDropdownMenu]="orgMenu"
    >
      <i nz-icon nzType="apartment" nzTheme="outline" style="margin-right:10px"></i>
      {{ orgCurrentName }}
    </div>
    <nz-dropdown-menu #orgMenu="nzDropdownMenu" id="orgListAll">
      <div
        nz-menu
        class="width-sm"
        *ngIf="isShowDropdownMenu"
        style="max-height:320px; overflow-y:auto; overflow-x:hidden"
      >
        <div nz-menu-item *ngFor="let orgItem of orgList" (click)="orgItemClick(orgItem)">
          {{ orgItem.cOrgName }}
        </div>
      </div>
    </nz-dropdown-menu>
  `,
})
export class HeaderOrgComponent {
  public orgList: any = [];
  public orgCurrentName = '无组织信息';
  // 组织下拉菜单显示标识
  public isShowDropdownMenu = false;

  public menuInfo: any = {};

  constructor(private orgServe: OrgChooseService,private yhFlowService: YhFlowService) {
    // 初始化当前组织名称
    this.setCurrentOrgName(this.orgServe.getCurrentOrg());

    this.orgServe.getMenuInfo(menuObj => {
      // console.log(menuOnj);
      if (!$.isEmptyObject(menuObj) && menuObj.cMenuCode !== undefined && menuObj.iMenuID !== undefined) {
        this.getOrgList(menuObj);

        this.menuInfo = {
          cMenuCode: menuObj.cMenuCode,
          cMenuName: menuObj.cMenuName,
          iMenuID: menuObj.iMenuID,
        };
      } else {
        this.setDefaultOrg();
      }
    });
  }

  // 获取组织列表信息
  getOrgList(obj) {
    this.orgServe.getOrgListInfo(
      obj.cMenuCode,
      obj.iMenuID,
      (res: any) => {
        // console.log(res);

        this.orgList = [];
        if (res.code === 1) {
          this.isShowDropdownMenu = true;

          for (const item of res.data) {
            this.orgList.push(item);
          }

          // 比对组织信息
          this.checkCurrentOrg();
        } else {
          console.warn(res.msg);
          this.setDefaultOrg();
        }
      },
      err => {
        console.warn(err);
        this.setDefaultOrg();
      },
    );
  }

  // 比对当前组织和组织列表内容 重新确定当前组织
  checkCurrentOrg() {
    // 初始化原有当前组织
    const currentOrg: any = this.orgServe.getCurrentOrg();

    let isNone = true;
    for (const orgItem of this.orgList) {
      if (orgItem.cOrgUUID === currentOrg.cOrgUUID) {
        isNone = false;
      }
    }
    if (isNone) {
      this.orgCurrentName = this.orgList[0].cOrgName;
      this.orgServe.setCurrentOrg(this.orgList[0]);
      // 初始化炎黄服务
      this.yhFlowService.initYhSever();
    }

    // 触发组织加载完毕事件
    setTimeout(() => {
      const mapObj = {
        cMenuCode: this.menuInfo.cMenuCode,
        cMenuName: this.menuInfo.cMenuName,
        iMenuID: this.menuInfo.iMenuID,
      };
      this.orgServe.orgLoadEventer.emit(mapObj);
    }, 100);
  }

  // 组织对象选择事件
  orgItemClick(currentOrg: any) {
    this.orgServe.setCurrentOrg(currentOrg);
    this.setCurrentOrgName(currentOrg);
    // 初始化炎黄服务
    this.yhFlowService.initYhSever();
    const mapObj = {
      cMenuCode: this.menuInfo.cMenuCode,
      cMenuName: this.menuInfo.cMenuName,
      iMenuID: this.menuInfo.iMenuID,
      cOrgUUID: currentOrg.cOrgUUID,
    };
    this.orgServe.orgChooseEventer.emit(mapObj);
  }

  // 当前组织名称赋值
  setCurrentOrgName(currentOrg: any) {
    // tslint:disable-next-line: prefer-conditional-expression
    if (currentOrg.cOrgName === undefined || currentOrg.cOrgName == null || currentOrg.cOrgName === '') {
      this.orgCurrentName = '默认组织';
    } else {
      this.orgCurrentName = currentOrg.cOrgName;
    }
  }

  // 设置空组织信息（默认）
  setDefaultOrg() {
    const tempObj: any = {
      cOrgCode: null,
      cOrgName: '',
      cOrgUUID: null,
      cShowTitle: '',
      iOrgID: null,
    };
    this.menuInfo = {
      cMenuCode: null,
      iMenuID: null,
    };

    this.orgServe.setCurrentOrg(tempObj);
    this.setCurrentOrgName(this.orgServe.getCurrentOrg());
    this.isShowDropdownMenu = false;

    // 触发组织加载完毕事件
    setTimeout(() => {
      const mapObj = {
        cMenuCode: this.menuInfo.cMenuCode,
        iMenuID: this.menuInfo.iMenuID,
      };
      this.orgServe.orgLoadEventer.emit(mapObj);
    }, 100);
  }
}
