import { Injectable, EventEmitter } from '@angular/core';
import { OrgChooseService } from './orgchoose.service'
import { HttpClient } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { YhFlowComponent } from '@shared/zw-flow';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class YhFlowService {

  sessionInterval: any  = null;// 炎黄session
  drawerRef:NzDrawerRef; // 侧滑抽屉
  public flowBtShowEventer = new EventEmitter<any>();
  cacheParam:any; // 缓存流程抽屉参数
  constructor(
    private http: HttpClient,
    private orgService: OrgChooseService,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) {}

  // 缓存炎黄服务信息
  setLocalYhSeverInfo(item: any){
    window.localStorage.setItem("yhSeverInfo",JSON.stringify(item))
  }
  // 获取炎黄服务缓存信息
  getLocalYhSeverInfo(){
    return JSON.parse(window.localStorage.getItem("yhSeverInfo"))
  }

  // 根据登录信息获取炎黄服务信息（是否启用bUse、cURL,token)
  getYhSeverInfo(success: Function,error: Function){
    let userInfo = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    let orgInfo = this.orgService.getCurrentOrg();
    var param = {
        "cPlatformCode": "AWS",
        "cAccUniqueID":userInfo.account.cAccUniqueID,
        "cOrgUUID": orgInfo.cOrgUUID,
        "cUserName": userInfo.cUserName,
        "cLoginType": "pc",
        "bLeaseAdmin": userInfo.bLeaseAdmin
    }
    
    // var param = {
    //     "cPlatformCode": "AWS",
    //     "cAccUniqueID": "pxy88cfd2f82f2c24002ada69cbb24382007",
    //     "cOrgUUID": "x9s3fce1425b5c2341b5b64afb76f9f1481b",
    //     "cUserName": "chenwenliang",
    //     "cLoginType": "pc"
    // }
    this.http.post(
      "/cnic-thirdplatform/Out_ConnectAWSAction/getToken",param
    ).subscribe(
      res => { 
        if(success){
          success(res);
        }
      },
      err => {
        if(error){
          error(err);
        }
      }
    )
  }
  // 获取流程任务列表
  getUserTaskList(success: Function,error: Function){
    let yhSeverInfo = this.getLocalYhSeverInfo()
    let server_url = yhSeverInfo.cURL+'/r/jd?cmd=com.awspaas.user.apps.aolianbase_getusertask&sid='+ yhSeverInfo.token;
    this.http.get(server_url
    ).subscribe(
      res => { 
        if(success){
          success(res);
        }
      },
      err => {
        if(error){
          error(err);
        }
      }
    )
  }
  // session续期
  refreshsession(success?: Function,error?: Function) {
    let yhSeverInfo = this.getLocalYhSeverInfo()
    let server_url = yhSeverInfo.cURL+'/r/jd?cmd=com.awspaas.user.apps.aolianbase_refreshsession&sid='+ yhSeverInfo.token
    this.http.get(server_url
      ).subscribe(
        res => { 
          if(success){
            success(res);
          }
        },
        err => {
          if(error){
            error(err);
          }
        }
      )
  }
  // 根据流程ID获取单个流程实例信息
  getProcessById(processDefId: string,processInstId: string,success: Function,error: Function) {
    let yhSeverInfo = this.getLocalYhSeverInfo()  
    var server_url = yhSeverInfo.cURL+'/r/jd?cmd=com.awspaas.user.apps.aolianbase_gettaskinfo4order&processDefId='+processDefId +'&processInstId='+processInstId+'&sid='+ yhSeverInfo.token
    this.http.get(server_url
      ).subscribe(
        res => { 
          if(success){
            success(res);
          }
        },
        err => {
          if(error){
            error(err);
          }
        }
      )
      
  }

  // 重新刷新炎黄session
  reRefresh() {
    if(this.sessionInterval){
      clearInterval(this.sessionInterval)
    }
    this.sessionInterval = setInterval(() => {
      this.refreshsession()
    },10*60*1000);
  }

  // 催办
  gotoUrge(processInstId: string,success: Function,error: Function){
    let yhSeverInfo = this.getLocalYhSeverInfo()
    var server_url = yhSeverInfo.cURL+'/r/jd?sid='+yhSeverInfo.token+'&cmd=com.awspaas.user.apps.aolianbase_remindbyid&processInstId='+processInstId
    this.http.get(server_url).subscribe(
        res => { 
          if(success){
            success(res);
          }
        },
        err => {
          if(error){
            error(err);
          }
        }
      )
  }

  // 初始化炎黄服务
  initYhSever(nextFun?){
    // 获取炎黄服务信息
    this.getYhSeverInfo(
      (res_yh) => {
        if(res_yh.code == 1){
            // 缓存炎黄服务信息
            this.setLocalYhSeverInfo(res_yh.data)

            if(res_yh.data.bUse){
              setTimeout(() => {
                this.reRefresh()
              }, 10*60*1000);
            }

        }else{
            console.log(res_yh.msg)
        }
        if(nextFun){
          nextFun();
        }
      },
      (err_yh) =>{
        if(nextFun){
          nextFun();
        }
          console.log(err_yh)
      }
    );
  }
  
  // 打开流程抽屉
  openFlowDraer(cMenuCode: string,title: string,url: string,ids: Array<string>){
    this.closeFlowDraer();
    let server_info = this.getLocalYhSeverInfo();
    
    this.cacheParam = {
      cMenuCode:cMenuCode,
      title:title,
      url:url,
      ids:ids
    }
    this.flowBtShowEventer.emit(false)
    this.drawerRef = this.drawerService.create<YhFlowComponent, { url: string,cMenuCode:string, ids: Array<string>}, string>({
      nzTitle: title,
      nzContent: YhFlowComponent,
      nzContentParams: {
        url: server_info.cURL+url,
        cMenuCode: cMenuCode,
        ids: ids
      },
      nzPlacement:'bottom',
      nzHeight:'80%',
      nzWidth:'90%',
      nzBodyStyle: {
        height: '100%'
      }
    });
    this.drawerRef.afterClose.subscribe(() => {
      this.flowBtShowEventer.emit(true)
    });
  }

  // 关闭流程抽屉
  closeFlowDraer(){
    this.drawerRef?this.drawerRef.close():null;
  }
  // 再次打开流程抽屉
  reOpenFlowDraer(){
    // this.drawerRef.open()
    this.openFlowDraer(this.cacheParam.cMenuCode,this.cacheParam.title,this.cacheParam.url,this.cacheParam.ids)
  }
  // 打开流程窗体-用于追踪
  opneFlowWin(cMenuCode: string,url: string,title: string) {

    this.modalService.create({
      nzTitle: title,
      nzContent: YhFlowComponent,
      nzComponentParams:{
        url: url,
        cMenuCode: cMenuCode
      },
      nzWidth:'80%'
    });
    
  }


  // 单据及流程打印
  flowPrint(cMenuCode: string,ids: Array<string>) {
    if(ids && ids.length > 0){
      // 用于流程iframe 返回消息监听,打印
      const printFunction = (event) => {
        // alert(JSON.stringify(event.data));
        if(!event.data || event.data == ""){
            return ;
        }
        let flowdata =event.data
        // 获取单据最后一个组件的位置
        let printdiv = document.createElement("div");
        let innerHtml = "";
        for (let index = 0; index < ids.length; index++) {
          const idstr = ids[index];
          innerHtml = innerHtml +$("#"+idstr).html();
        }
        innerHtml = innerHtml +flowdata.innerHTML.replace('<table class="awsui-ux">','<table class="awsui-ux" style="width: 100%;">');
        printdiv.innerHTML = innerHtml;
        
        $(printdiv).printArea();
      }

      window.removeEventListener('message',printFunction)
      window.addEventListener('message', printFunction);

          //向炎黄流程iframe 发送消息
      let frame:any = document.getElementById('process_iframe_'+cMenuCode); 
      let data:any = {
        title:'',
        method:''
      };
      data.title = "process_iframe_"+cMenuCode;
      data.method = "getShenPiDOM";
      frame.contentWindow.postMessage (data, '*');
    }

  }

}
