import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuChooseService } from '../../services/menuchoose.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent implements OnInit {
  // 菜单对象
  public menuObj: any = [];

  constructor(private router: Router, private menuServe: MenuChooseService) {
    // 监听菜单切换
    this.menuServe.menuChooseEventer.subscribe((obj: any) => {
      this.menuObj = obj.children;
    });
  }

  ngOnInit() {}

  // 菜单项点击事件
  menuItemClickEvent(url: string) {
    this.router.navigateByUrl(url);
  }
}
