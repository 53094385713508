import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NzContextMenuService } from 'ng-zorro-antd';
import { YhFlowService } from 'src/app/services/yhFlow.service';
import { NoticeIconList, NoticeIconSelect, NoticeItem } from '@delon/abc/notice-icon';
@Component({
  selector: 'header-task',
  template: `
    <div
      class="alain-default__nav-item"
      nz-dropdown
      [(nzVisible)]="visible"
      [nzDropdownMenu]="taskMenu"
      nzTrigger="click"
      nzPlacement="bottomRight"
      (nzVisibleChange)="change()"
    >
      <nz-badge [nzDot]="true">
        <i nz-icon nzType="bars" nzTheme="outline"></i>
      </nz-badge>
    </div>
    <nz-dropdown-menu #taskMenu="nzDropdownMenu">
      <div nz-menu class="wd-lg">
        <div *ngIf="loading" class="mx-lg p-lg">
          <nz-spin></nz-spin>
        </div>
        <nz-tabset *ngIf="!loading" [nzSize]="'small'">
          <nz-tab nzTitle="待办">
            <nz-list
              class="demo-loadmore-list"
              [nzDataSource]="todoList"
              [nzItemLayout]="'horizontal'"
              [nzRenderItem]="item"
              [ngStyle]="{'margin': '0px 10px'}"
            >
              <ng-template #item let-item>
                <nz-list-item style="cursor:pointer;"
                [nzExtra]="'状态'"
                >
                  <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzLoading]="item.loading">
                    <nz-list-item-meta
                      [nzTitle]="nzTitle"
                      [nzDescription]="desc"
                      (click)="goToDetail(item)"
                      
                    >
                      <ng-template #nzTitle style="cursor:pointer;">
                        {{item.title}}
                      </ng-template>
                      <ng-template #desc>
                      来自: {{item.ownerName}} 开始时间：{{item.beginTimeStr}}
                      </ng-template>
                    </nz-list-item-meta>
                  </nz-skeleton>
                </nz-list-item>
              </ng-template>
              <ng-template #loadMore>
                <div style="text-align: center;">
                  <button nz-button nzType="link" (click)="onLoadMore()">查看更多</button>
                </div>
              </ng-template>
            </nz-list>
          </nz-tab>
          <nz-tab nzTitle="已办">
            <nz-list
              class="demo-loadmore-list"
              [nzDataSource]="doneList"
              [nzItemLayout]="'horizontal'"
              [nzRenderItem]="item"
              [ngStyle]="{'margin': '0px 10px'}"
            >
              <ng-template #item let-item>
                <nz-list-item
                >
                  <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzLoading]="item.loading">
                    <nz-list-item-meta
                      [nzTitle]="nzTitle"
                      [nzDescription]="desc"
                      (click)="goToDetail(item)"
                    >
                      <ng-template #nzTitle>
                        {{item.title}}
                      </ng-template>
                      <ng-template #desc>
                      来自: {{item.ownerName}} 开始时间：{{item.beginTimeStr}}
                      </ng-template>
                    </nz-list-item-meta>
                  </nz-skeleton>
                </nz-list-item>
              </ng-template>
              <ng-template #loadMoreDone>
                <div style="text-align: center;">
                  <button nz-button nzType="link" (click)="onLoadMore()">查看更多</button>
                </div>
              </ng-template>
            </nz-list>
          </nz-tab>
          <nz-tab nzTitle="已发">
            <nz-list
              class="demo-loadmore-list"
              [nzDataSource]="createList"
              [nzItemLayout]="'horizontal'"
              [nzRenderItem]="item"
              [ngStyle]="{'margin': '0px 10px'}"
            >
              <ng-template #item let-item>
                <nz-list-item

                >
                  <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzLoading]="item.loading">
                    <nz-list-item-meta
                      [nzTitle]="nzTitle"
                      [nzDescription]="desc"
                      (click)="goToDetail(item)"
                    >
                      <ng-template #nzTitle>
                        {{item.title}}
                      </ng-template>
                      <ng-template #desc>
                      来自: {{item.ownerName}} 开始时间：{{item.beginTimeStr}}
                      </ng-template>
                    </nz-list-item-meta>
                  </nz-skeleton>
                </nz-list-item>
              </ng-template>
              <ng-template #loadMoreCreate>
                <div style="text-align: center;">
                  <button nz-button nzType="link" (click)="onLoadMore()">查看更多</button>
                </div>
              </ng-template>
            </nz-list>
          </nz-tab>
        </nz-tabset>
      </div>
    </nz-dropdown-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

// @Component({
//   selector: 'header-task',
//   template: `
//     <notice-icon
//       [data]="data"
//       [count]="count"
//       [loading]="loading"
//       btnClass="alain-default__nav-item"
//       btnIconClass="alain-default__nav-item-icon"
//       (select)="select($event)"
//       (clear)="clear($event)"
//       (popoverVisibleChange)="loadData()"
//     ></notice-icon>
//   `,
//   changeDetection: ChangeDetectionStrategy.OnPush
// })
export class HeaderTaskComponent {
  // data: NoticeItem[] = [
  //   {
  //     title: '通知',
  //     list: [],
  //     emptyText: '你已查看所有通知',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
  //     clearText: '清空通知'
  //   },
  //   {
  //     title: '消息',
  //     list: [],
  //     emptyText: '您已读完所有消息',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg',
  //     clearText: '清空消息'
  //   },
  //   {
  //     title: '待办',
  //     list: [],
  //     emptyText: '你已完成所有待办',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg',
  //     clearText: '清空待办'
  //   }
  // ];
  // count = 5;
  visible = false;// 下拉菜单是否可见
  loading = true;// 加载
  doneList = [];// 已办
  todoList = [];// 待办
  createList = [];// 已发
  constructor(
    private cdr: ChangeDetectorRef,
    private yhFlowService: YhFlowService,
    private router: Router,
    private menuService:NzContextMenuService
    ) {}
  ngOnInit() {
    this.yhFlowService.getUserTaskList(
      (res:any) => {
        if(res.result == "ok"){
          if(res.data){
              this.doneList = res.data.doneList? res.data.doneList:[]
              this.todoList = res.data.todoList? res.data.todoList:[]
              this.createList = res.data.createList? res.data.createList:[]
          }else{
              return;
          }
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 跳转详细功能页面
  goToDetail(rowdata){
    debugger;
    if(rowdata.oid && rowdata.route && rowdata.route.length > 0){
      this.router.navigateByUrl(rowdata.route,{
        queryParams:{
          cMenuUrl:rowdata.route,
          cMenuCode:rowdata.code,
          cMenuName:rowdata.name,
          oid:rowdata.oid
        }
      })

    }

    this.visible = false;
    this.router.navigate(['DCAS-DataReport/viewAppeals'],{
      queryParams:{
        cMenuUrl:'DCAS-DataReport/viewAppeals',
        cMenuCode:"02230302230502",
        cMenuName:"申诉查看",
        oid:"1111",
        openUrlFragment:rowdata.openUrlFragment
      }
    });
    
  }

  // 跳转更多
  onLoadMore(){

  }


  change() {
    setTimeout(() => {
      this.loading = false;
      this.cdr.detectChanges();
    }, 500);
  }
}
