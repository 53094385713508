import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SettingsService, _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { ToolsService } from 'src/app/services/tools.service';
import { NzModalService } from 'ng-zorro-antd/modal'; 

declare var $: any;

@Component({
  selector: 'header-user',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm"
      nz-dropdown
      nzPlacement="bottomRight"
      [nzDropdownMenu]="userMenu"
    >
      <i nz-icon nzType="user" nzTheme="outline" style="margin-right:10px"></i>
      {{ username }}
    </div>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <div nz-menu-item (click)="showEnterpriseList()">
          <i nz-icon nzType="swap" class="mr-sm"></i>
          切换机构
        </div>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          退出登录
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-modal [(nzVisible)]="isEnterpriseSelectVisible"
      nzWrapClassName="vertical-center-modal"
      [nzBodyStyle]="modelStyle"
      nzWidth="350"
      nzTitle="企业（机构）选择"
      [nzFooter]="nullObj"
      (nzOnCancel)="enterpriseSelectClose()">
      <ng-container>
        <nz-card>
          <div *ngFor="let item of enterpriseList"
           nz-card-grid
           [ngStyle]="cardGridStyle"
           (click)="btn_Enterprise_Click(item)">
            <div style="font-size: 16px; font-weight: bold;">
             {{ item.fullName }}
            </div>
            <div style="font-size: 14px;"> 
              企业识别码：{{ item.numberName }} 
              <span style="float:right" *ngIf="item.isCurrentEnterprise">
                <i nz-icon nzType="check" nzTheme="outline"></i>
              </span>
            </div>
          </div>
        </nz-card>
      </ng-container>
    </nz-modal>
    <nz-modal [(nzVisible)]="isPasswordALConfirmVisible"
      nzWrapClassName="vertical-center-modal"
      nzWidth="350"
      [nzTitle]="pwdALTitle"
      [nzCancelText]="nullObj"
      [nzOkLoading]="isPasswordALLoading"
      (nzOnOk)="checkUserAL()"
      (nzOnCancel)="passwordAlConfirmClose()">
      <ng-container>
        <form nz-form>
          <nz-form-item style="margin-bottom:0px">
            <nz-form-control>
              <nz-input-group nzPrefixIcon="lock">
                <input id="passwordALConfirm" type="password" nz-input placeholder="请输入奥链账号密码" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </form>
      </ng-container>
    </nz-modal>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderUserComponent {
  // 登录用户信息
  public loginInfo: any;
  // 登录用户名称
  public username: string;  

  // 归属企业列表
  public enterpriseList: Array<any> = [];
  // 归属企业列表窗体 显示标识
  public isEnterpriseSelectVisible = false;
  // 奥链账号密码确认窗体 显示标识
  public isPasswordALConfirmVisible = false;
  // 奥链账号密码确认窗体 确定按钮loading标识
  public isPasswordALLoading = false;

  // 奥链账号密码确认窗体标题
  public pwdALTitle = "";
  // 空对象 用于禁用模态页脚
  public nullObj = null;

  // 当前选择的企业对象
  public currentEnterpriseSelect = {};

  // card frid样式
  public cardGridStyle = {
    'width' : '100%'
  }
  // 模态框最大高度
  public modelStyle = {
    'height' : '345px',
    'overflow-y' : 'auto'
  }

  constructor(
    public http: _HttpClient,
    public settings: SettingsService,
    private toolsService: ToolsService,
    private modal: NzModalService,
    private message: NzMessageService
  ) {
    // 初始化登录人员名称
    this.loginInfo = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.loginInfo.cPersonName !== undefined && this.loginInfo.cPersonName !== null && this.loginInfo.cPersonName !== '') {
      this.username = this.loginInfo.cPersonName;
    } else {
      this.username = '系统用户';
    }

    if(this.loginInfo.allLink !== undefined && this.loginInfo.allLink !== null){
      this.pwdALTitle = "奥链账号（" + this.loginInfo.allLink.cMemberRegisterPhone + "）确认";
    }    
  }

  public showEnterpriseList () {
    if (this.loginInfo.allLink === undefined || this.loginInfo.allLink === null){
      this.message.error("您尚未绑定奥链统一账号！");
    }
    else{
      this.toolsService.setFetchingStatus(true);

      this.http.get('/cnic-publicresource/Pub_FocusBusinessNumberAction/getMemberSubscription/' + this.loginInfo.allLink.cPUSigID + '/01/true/2')
      .subscribe(
        (res: any) => {
          this.toolsService.setFetchingStatus(false);

          if (res.code === 1) {
            // console.log(res);
            this.enterpriseList.length = 0;
            for (const obj of res.data) {
              let isCurrentBus = false;
              if(obj.enterpriseData.cCNICSigID === window.localStorage.getItem('SinID')){
                  isCurrentBus = true;
              }
              this.enterpriseList.push(
                {
                  "fullName" : obj.enterpriseData.cEFullName, // 企业名称
                  "numberName" : obj.cBusinessNumberName, // 企业关注号名称
                  "sigID" : obj.enterpriseData.cCNICSigID,  // 企业识别码
                  "businessUserUUID" : obj.cBusinessUserUUID, // 业务用户UUID
                  "businessUserName" : obj.cBusinessUserName, // 业务用户用户名
                  "isCurrentEnterprise" : isCurrentBus,
                }
              )
            }

            this.isEnterpriseSelectVisible = true;
          }
          else{
            this.message.error(res.msg);
          }
        },
        (error: any) => {
          this.toolsService.setFetchingStatus(false);
          this.message.error('机构信息获取失败...');
        },
        () => {
          this.toolsService.setFetchingStatus(false);
        }
      )
    }
  }
  // 企业选择窗体关闭事件
  public enterpriseSelectClose(){
    this.isEnterpriseSelectVisible = false;
  }
  // 奥链密码确认窗体关闭事件
  public passwordAlConfirmClose(){
    this.isPasswordALConfirmVisible = false;
  }
  // 企业（机构）列表项 点击事件
  btn_Enterprise_Click(list: any){
    // 校验是否当前归属的企业
    if(list.isCurrentEnterprise){
      return;
    }

    // 关闭选择项窗体
    this.enterpriseSelectClose();

    // 存当前选择项数据对象
    this.currentEnterpriseSelect = list;

    // 校验是否需求奥链统一账号验证（通过业务账号登录的情况）
    if(window.sessionStorage.getItem("loginType") === "gov"){
      // 打开密码确认模态窗
      this.isPasswordALConfirmVisible = true;
    }
    else{
      // 触发新切换企业信息获取
      this.getEnterpriseInfo(list);
    }                
  }
  // 校验奥链账号
  public checkUserAL(){
    // 校验密码
    const pwd = $("#passwordALConfirm").val();
    if(pwd.trim() === ""){
      this.message.error('密码不能为空');
      return;
    }

    this.isPasswordALLoading = true;

    this.http.post('/cnic-auth/BaseManageActionV1/checkAllLinkLogin',
      {
        "cUserName": this.toolsService.encrypt(this.loginInfo.allLink.cMemberRegisterPhone),
        "cPsd": this.toolsService.encrypt(pwd),
        "bEncrypt": true,
        "iCheckType": 1
      }
    )
    .subscribe(
      (res: any) => {
        this.isPasswordALLoading = false;

        if (res.code === 1) {
          // 触发新切换企业信息获取
          this.getEnterpriseInfo(this.currentEnterpriseSelect);
        }
        else{
          this.message.error(res.msg);
        }
      },
      (error: any) => {
        this.isPasswordALLoading = false;
        this.message.error('奥链账号校验失败...');
      },
      () => {
        this.isPasswordALLoading = false;
      }
    )
  }
  // 重新获取新的企业信息
  getEnterpriseInfo(list: any){
    this.http.post("/cnic-auth/ZwzqAction/getDataNoLogin",{
      "cUserName" : list.businessUserName,
      "cUserUUID" : list.businessUserUUID,
      "cLoginDate" : this.toolsService.getNowFormatDateYMD(),
      "cCNICSigID" : list.sigID,
      "cUUID" : window.sessionStorage.getItem('SID'),
      "iType" : 1
    })
    .subscribe(
      (res: any) => {
        if (res.code === 1) {
          const user = res.data;

          // 判定用戶是否其他PC端登录
          if(user.bAlreadyLogin){
            this.modal.confirm({
              nzTitle: '系统提示',
              nzContent: '切换的账户已在别处登录，是否强制登录?',
              nzWrapClassName: 'vertical-center-modal',
              nzOnOk: () => {
                this.modal.closeAll();
                this.forcedToLogin (user.sys_User, (fuser: any)=>{
                  // 处理登录信息
                  this.toolsService.loginInfoHandle(fuser, list.sigID);
                  setTimeout(() => {
                    window.location.href = window.location.protocol + "//" + window.location.hostname + ':' + window.location.port + "/";
                    // this.router.navigateByUrl("/");
                  }, 100);
                });
              }
            });
          }
          else{
            // 处理登录信息
            this.toolsService.loginInfoHandle(user, list.sigID);
            setTimeout(() => {
              window.location.href = window.location.protocol + "//" + window.location.hostname + ':' + window.location.port + "/";
              // this.router.navigateByUrl("/");
            }, 100); 
          }        
        }
        else {
          this.message.error(res.msg);
        }
      },
      (error: any) => {
        this.message.error('连接服务器失败...');
      },
      () => {
      }
    );
  }

  // 强制登录操作
  forcedToLogin(param: any, nextFun){
    this.toolsService.setFetchingStatus(true);

    this.http.post("/cnic-auth/ZwzqAction/getDataNoLogin",{
      "cUserUUID" : param.cUserUUID,
      "cUserName" : param.cUserName,
      "cLoginDate" : this.toolsService.getNowFormatDateYMD(),
      "cCNICSigID" : param.cCNICSigID,
      "cUUID" : window.sessionStorage.getItem('SID'),
      "iType" : 1,
      "isLogin" : true
    })
    .subscribe(
      (res: any) => {
        this.toolsService.setFetchingStatus(false);
        if (res.code === 1) {
          if(nextFun){
            nextFun(res.data);
          }
        }
        else {
          this.message.error(res.msg);
        }
      },
      (error: any) => {
        this.toolsService.setFetchingStatus(false);
        this.message.error('连接服务器失败...');
      },
      () => {
        this.toolsService.setFetchingStatus(false);
      }
    );
  }

  // 退出登录
  logout() {
    this.toolsService.logout();
  }
}
