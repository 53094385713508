import { EventEmitter, Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { DatePipe } from '@angular/common';

// import { ToolsService } from './tools.service';
// import { URLService } from './url.service';
// import { UserService } from './user.service';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  // API服务地址（公共微服务）
  serverMicro = '';

  // 未读消息列表对象
  private noReadNotice = [];

  // 未读消息列表改变事件对象
  public noticeChangeEvent = new EventEmitter<any>();
  // 未读消息列表添加事件对象
  public noticeAddEvent = new EventEmitter<any>();
  // 企业诉求弹出模态事件
  public appealModal = new EventEmitter<any>();

  constructor(
    private http: _HttpClient,
    private datePipe:DatePipe
  ) {
    // 获取公共微服务地址
    // TODO: Setting micro service url.
    this.serverMicro = '';
  }

  // 获取未读消息列表
  getNoticeNoRead(userUUID: string, type: string, count: number, success?, error?) {
    const map: any = {
      cUUID: userUUID,
      cType: type,
      bSetRead: false,
    };
    if (count > 0) {
      map.iCount = count;
    }

    // 调用登录服务api
    this.http.post('/cnic-message/Msg_SendMsgAPIV1/getNoReadMsg', map).subscribe(
      (res: any) => {
        if (success !== undefined) {
          success(res);
        }
      },
      (err: any) => {
        if (error !== undefined) {
          error(err);
        }
      },
    );
  }

  // 改变消息已读状态
  setNoticeRead(noticeid: string, success?, error?) {
    this.http
      .post('/cnic-message/Msg_SendMsgAPIV1/setMsgRead', { iMsgReceiverIDs: noticeid })
      .subscribe(
        (res: any) => {
          if (success !== undefined) {
            success(res);
          }
        },
        (err: any) => {
          if (error !== undefined) {
            error(err);
          }
        },
      );
  }

  // 初始化未读消息对象
  initNotice(notice: any) {
    //TODO: Get user info (from sessionStorage)
    const userInfo: any = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    const euuid = userInfo.account.cEUUID;

    this.noReadNotice.length = 0;
    for (const obj of notice) {
      if (
        obj.currentInfoDto !== undefined &&
        obj.currentInfoDto !== null &&
        obj.currentInfoDto.cEUUID !== undefined &&
        obj.currentInfoDto.cEUUID !== null &&
        obj.currentInfoDto.cEUUID === euuid
      ) {
        obj.id = obj.iMsgID;
        obj.title = obj.cMsgTitle;
        obj.dSendSysTimeLong = this.datePipe.transform(new Date(obj.dSendSysTimeLong),"yyyy-MM-dd HH:mm:ss");
        obj.datetime = obj.dSendSysTimeLong;
        obj.read = false;
        this.noReadNotice.push(obj);
      }
    }
    this.noticeChangeEvent.emit(this.noReadNotice);
  }

  // 增加消息 1条
  addNotice(msg: any) {
    //TODO: Get user info (from sessionStorage)
    const userInfo: any = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    const euuid = userInfo.account.cEUUID;

    const targetMsg: any = {};
    // 为了数据统一，转换数据结构 20201216-gf
    msg = this.msgFormat(msg);

    Object.assign(targetMsg, msg);

    if (
      targetMsg.currentInfoDto !== undefined &&
      targetMsg.currentInfoDto !== null &&
      targetMsg.currentInfoDto.cEUUID !== undefined &&
      targetMsg.currentInfoDto.cEUUID !== null &&
      targetMsg.currentInfoDto.cEUUID === euuid
    ) {
      targetMsg.id = targetMsg.iMsgID;
      targetMsg.title = targetMsg.cMsgTitle;

      targetMsg.dSendSysTimeLong = this.datePipe.transform(new Date(targetMsg.dSendSysTimeLong),"yyyy-MM-dd HH:mm:ss");
      targetMsg.datetime = targetMsg.dSendSysTimeLong;

      targetMsg.read = false;

      this.noReadNotice.unshift(targetMsg);
      this.noticeChangeEvent.emit(this.noReadNotice);

      // 触发新消息事件
      this.noticeAddEvent.emit(targetMsg);
    }
  }

  // 删除消息 1条或多条
  deleteNotice(msgID: string) {
    const msgidArray = msgID.split(',');

    msgidArray.forEach(id => {
      let index = 0;
      for (const obj of this.noReadNotice) {
        if (id === obj.iMsgID) {
          this.noReadNotice.splice(index, 1);
          break;
        }
        index++;
      }
    });

    this.noticeChangeEvent.emit(this.noReadNotice);
  }

      /**
     * 获取消息列表
     * @param iPageNumber 当前第几页
     * @param cType "消息发送类型：01:微信 \t *02:奥链云移动端 \t *03:奥链云PC端 \t *04:短信 \t *05:eMail \t *06:政务云PC端 \t *07:政务云移动端 \t *08:运维系统 \t *09:运营系统 \t *10:PC商城",
     * @param cMsgState "查询的消息的状态 0--查询全部 01--未读 02--已读   默认未读",
     * @param cMsgTypeCode  //"消息类型 参照Msg_MsgTypeEnum \t * 01--系统通知 \t * 02--警告通知 \t * 03--应用通知 \t * 04--新的好友 \t * 05--交易通知 \t * 06--物流通知 \t * 07--奥链团队 \t * 08--奥链运营团队 \t * 09--奥链安全助手 \t * 10--通知 \t * 11--日程 \t * 12--任务",
     */
    getMsgList(iPageNumber:number,cType:string,cMsgState:string,cMsgTypeCode:string,success?,error?){
      // let user = this.userinfoService.getUser();
      let buser: any = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
      let param = {
        "cType": cType,//"消息发送类型：01:微信 \t *02:奥链云移动端 \t *03:奥链云PC端 \t *04:短信 \t *05:eMail \t *06:政务云PC端 \t *07:政务云移动端 \t *08:运维系统 \t *09:运营系统 \t *10:PC商城",
        "cUUID": buser.cUserUUID,//"接收人接收地址",
        //"cReceiver": '',//"接收人名称",
        "cEUUID":buser.account.cEUUID, //"接收人业务账号所属账套cEUUID",
        "cUserUUID": buser.cUserUUID,//"接收人业务账号UUID",
        "cPUSigID":"",//"接收人奥链UUID",
        //"cMsgTitle": "消息标题，模糊查询",
        "cMsgState": cMsgState,//"查询的消息的状态 0--查询全部 01--未读 02--已读   默认未读",
        "bPage": true,//"是否分页查询，默认分页",
        "iCount": 200,//"每页数量",
        "iPageNumber":iPageNumber, //"查询的当前页码",
        "iMsgID": null,//"分页、非首页时，已拉取的消息主表ID",
        "bSetRead": false,//"是否获取完，直接设置为已读，默认设置已读",
        "cMsgTypeCode": cMsgTypeCode,//"消息类型 参照Msg_MsgTypeEnum \t * 01--系统通知 \t * 02--警告通知 \t * 03--应用通知 \t * 04--新的好友 \t * 05--交易通知 \t * 06--物流通知 \t * 07--奥链团队 \t * 08--奥链运营团队 \t * 09--奥链安全助手 \t * 10--通知 \t * 11--日程 \t * 12--任务",
        "cWhere": null//"其他个性化and查询条件"
      };

      this.http.post('/cnic-message/Msg_SendMsgAPIV1/getMsgPage4Receiver',param)
      .subscribe(
           (res:any) => {
            if(success){
              success(res);
            }
           },
           (err:any) => {
             // 静默拉取不做处理
             if(error){
              error()
            }
           }
         )
    }

    /**
     * 数据结构转换：用于统一显示
     * 20201216 gf
     * @param msg websocket推送来的消息对象
     */
    msgFormat(msg){
      if(msg['cTitle']){
        msg['cMsgTitle'] = msg['cTitle'];
      }
      if(msg['cSendTime']){
        msg['dSendSysTime'] = msg['cSendTime'];
        msg['dSendSysTimeLong'] = new Date(msg['cSendTime']).getTime();
      }
      if(msg['cBody']){
        msg['cMsgBody'] = msg['cBody'];
      }
      if(msg['cMsgSysType']){
        msg['cMsgReceiveType'] = msg['cMsgSysType'];
      }
      if(msg['cSendUser']){
        msg['cSender'] = msg['cSendUser'];
      }
      return msg;
    }

}
