import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WarningOutline } from '@ant-design/icons-angular/icons';
import { LoadingService } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-shandongtong-login',
  templateUrl: './shandongtong-login.component.html',
  styles: []
})
export class ShanDongTongLoginComponent implements OnInit {
  viewRef: 'commonError';
  errorMsg: string;
  isLoading = false;
  UUID = '';
  WarningOutline = WarningOutline;

  constructor(
    private loadingSrv: LoadingService,
    private http: HttpClient,
    private msg: NzMessageService,
    private router: Router,
    private modalSrv: NzModalService,
    private toolsService: ToolsService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit() {
    // 显示加载动画
    this.loadingSrv.open({ type: 'icon' });
    const params = new URLSearchParams(window.location.search);
    // 山东通平台用户授权code，点击图标跳转到该页面，带过来的query参数。
    const code = params.get('code');
    // 组织id，需要AES加密，取自配置文件
    let corpID = undefined;
    // 应用id，需要AES加密，取自配置文件
    let agentId = undefined;
    // 应用的凭证秘钥，需要AES加密，取自配置文件
    let secret = undefined;
    // 对应账套识别码，取自配置文件
    let cCNICSigID = undefined;
    // auth微服务地址，取自配置文件
    let authMicroServicesUrl = undefined;
    // 山东通平台地址，取自配置文件
    let shanDongTongUrl = undefined;

    console.log("读配置前cCNICSigID:" + cCNICSigID)

    // 读取配置文件
    this.http.get('assets/project-info.json').subscribe(
      (res: any) => {
        // 存储系统设置信息
        corpID = res.shandongtongConfig.corpID;
        agentId = res.shandongtongConfig.agentId;
        secret = res.shandongtongConfig.secret;
        cCNICSigID = res.shandongtongConfig.cCNICSigID;
        authMicroServicesUrl = res.shandongtongConfig.authMicroServicesUrl;
        shanDongTongUrl = res.shandongtongConfig.shanDongTongUrl;

        console.log("读配置后cCNICSigID:" + cCNICSigID)

        // 缺少 code 参数则中断流程
        if (!code) {
          this.viewRef = 'commonError';
          this.errorMsg = '参数错误';
          this.loadingSrv.close();
          return 
        }

        this.UUID = this.getUUID()
        this.http.post('/cnic-auth/ZwzqAction/login4OAuthTokenShanDongTong', {
          "code" : code,
          "corpID" : corpID,
          "agentId" : agentId,
          "secret" : secret,
          "cCNICSigID" : cCNICSigID,
          "authMicroServicesUrl" : authMicroServicesUrl,
          "shanDongTongUrl" : shanDongTongUrl,
        }).subscribe((res: any) => {
          if (res.code === 1) {
            const user = res.data.loginMap;

            // 判定用戶是否其他PC端登录
            if(user.bAlreadyLogin){
              this.modalSrv.confirm({
                nzTitle: '系统提示',
                nzContent: '当前账户已在别处登录，是否强制登录?',
                nzWrapClassName: 'vertical-center-modal',
                nzOnOk: () => {
                  this.modalSrv.closeAll();
                  this.forcedToLogin(user.sys_User, (fuser: any)=>{
                    this.loginInfoExe(fuser, fuser.account.cCNICSigID);
                  })
                }
              });
            } else {
              this.loginInfoExe(user, res.data.cCNICSigID);
            }
          } else {
            this.viewRef = 'commonError';
            this.errorMsg = res.msg;
          }
        }, () => {
          this.viewRef = 'commonError';
          this.errorMsg = '参数错误';
        }, () => {
          this.loadingSrv.close();
        });
      }
    );

  }

  // 生成UUID
  getUUID() {
    return (
      this.randomStr() +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      this.randomStr() +
      this.randomStr()
    );
  }
  // 创建随机字符串
  randomStr() {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  // 强制登录操作
  forcedToLogin(param: any, nextFun){
    this.isLoading = true;

    this.http.post("/cnic-auth/ZwzqAction/getDataNoLogin",{
      cUserUUID : param.cUserUUID,
      cUserName : param.cUserName,
      cLoginDate : this.toolsService.getNowFormatDateYMD(),
      cCNICSigID : param.cCNICSigID,
      cUUID : this.UUID,
      iType : 1,
      isLogin : true
    })
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.isLoading = true;
            if(nextFun){
              nextFun(res.data);
            }
          }
          else {
            this.message.error(res.msg);
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.message.error('连接服务器失败...');
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  // 处理用户登录信息
  loginInfoExe(res: any, cCNICSigID){
    // 处理登录信息
    this.toolsService.loginInfoHandle(res, cCNICSigID,
      () => {
        // 保存UUID
        window.sessionStorage.setItem('SID', this.UUID);
        // 保存登录类
        window.sessionStorage.setItem('loginType', 'gov');
        // 初始化FBI
        this.toolsService.initFBI(res);

        // 跳转工作台首页
        this.router.navigateByUrl('/', {
          replaceUrl: true,
          state: {

          },
        });
      });
  }
}
