import { Component, OnInit } from '@angular/core';
import { XlsxService, STColumn } from '@delon/abc';

@Component({
  selector: 'zw-excel',
  template: `
    <nz-card nzTitle="导入">
      <button nz-button (click)="url()">Via Url</button>
      <input type="file" (change)="change($event)" multiple="false" class="ml-sm" />
      <p class="mt-sm">result: {{ dataExcel | json }}</p>
    </nz-card>
  `,
})
export class zwExcelComponent {
  constructor(private xlsx: XlsxService) {}
  dataExcel: any;

  users: any[] = Array(100)
    .fill({})
    .map((item: any, idx: number) => {
      return {
        id: idx + 1,
        name: `name ${idx + 1}`,
        age: Math.ceil(Math.random() * 10) + 20,
      };
    });

  columns: STColumn[] = [
    { title: '编号', index: 'id', type: 'checkbox' },
    { title: '姓名', index: 'name' },
    { title: '年龄', index: 'age' },
  ];
  url() {
    this.xlsx.import(`./assets/demo/demo.xlsx`).then(res => (this.dataExcel = res));
  }

  change(e: Event) {
    const file = (e.target as HTMLInputElement).files![0];
    this.xlsx.import(file).then(res => (this.dataExcel = res));
  }

  download() {
    const data = [this.columns.map(i => i.title)];
    this.users.forEach(i => data.push(this.columns.map(c => i[c.index as string])));
    this.xlsx.export({
      sheets: [
        {
          data,
          name: 'sheet name',
        },
      ],
    });
  }
}
