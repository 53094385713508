import { Injectable, EventEmitter } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class MenuChooseService {
  public menuChooseEventer = new EventEmitter<any>();

  constructor() {}

  // 设置并存储当前菜单对象
  public setCurrentMenu(orgObj: any) {
    window.sessionStorage.setItem('zw_currentMenu', JSON.stringify(orgObj));
  }

  // 获取当前菜单对象
  public getCurrentMenu() {
    return JSON.parse(window.sessionStorage.getItem('zw_currentMenu'));
  }
}
