/*
 * Automatically generated by 'ng g ng-alain:plugin icon'
 * @see https://ng-alain.com/cli/plugin#icon
 */

import {
  AppstoreOutline,
  ArrowDownOutline,
  CloudOutline,
  ExportOutline,
  FullscreenExitOutline,
  FullscreenOutline,
  LockOutline,
  LogoutOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  PayCircleOutline,
  PrinterOutline,
  SaveOutline,
  ScanOutline,
  SettingOutline,
  StarOutline,
  TeamOutline,
  ToolOutline,
  UserOutline,
  TagOutline,
  SafetyOutline,
  FolderOutline,
  HomeOutline,
  ApartmentOutline,
  ProjectOutline,
  DownloadOutline,
  UploadOutline,
  CalculatorOutline,
  AuditOutline,
  FileExcelOutline,
  RightSquareOutline,
  ReloadOutline,
  PictureTwoTone,
  FileOutline,
  FrownOutline,
  CarryOutOutline,
  SmileOutline,
  SwapOutline,
  RetweetOutline,
  MinusSquareOutline,
  BookOutline
} from '@ant-design/icons-angular/icons';

export const ICONS_AUTO = [
  AppstoreOutline,
  ArrowDownOutline,
  CloudOutline,
  ExportOutline,
  FullscreenExitOutline,
  FullscreenOutline,
  LockOutline,
  LogoutOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  PayCircleOutline,
  PrinterOutline,
  SaveOutline,
  ScanOutline,
  SettingOutline,
  StarOutline,
  TeamOutline,
  ToolOutline,
  UserOutline,
  TagOutline,
  SafetyOutline,
  FolderOutline,
  HomeOutline,
  ApartmentOutline,
  ProjectOutline,
  DownloadOutline,
  UploadOutline,
  CalculatorOutline,
  AuditOutline,
  FileExcelOutline,
  RightSquareOutline,
  ReloadOutline,
  PictureTwoTone,
  FileOutline,
  FrownOutline,
  CarryOutOutline,
  SmileOutline,
  SwapOutline,
  RetweetOutline,
  MinusSquareOutline,
  BookOutline
];
