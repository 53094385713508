// Custom icon static resources

import {
  InfoOutline,
  BulbOutline,
  ProfileOutline,
  ExceptionOutline,
  LinkOutline,
  ReloadOutline
} from '@ant-design/icons-angular/icons';

export const ICONS = [InfoOutline, BulbOutline, ProfileOutline, ExceptionOutline, LinkOutline, ReloadOutline];
