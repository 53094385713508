import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
} from '@angular/router';
import { ToolsService } from './services/tools.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private toolsService: ToolsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }

  // 检测当前是否登录
  checkLogin(): boolean {
    const loginInfo: any = window.sessionStorage.getItem('zw_loginInfo');
    if (
      loginInfo === undefined ||
      loginInfo === null ||
      loginInfo === '' ||
      JSON.parse(loginInfo).cPersonName === undefined
    ) {
      this.toolsService.doLogout();
      return false;
    }

    return true;
  }
}
