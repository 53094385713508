import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { RouteRoutingModule } from './routes-routing.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

// 控制台
import { MainComponent } from './main/main.component';
// 登录
import { UserLoginComponent } from './login/login.component';
// 授权登录
import { AuthLoginComponent } from './auth-login/auth-login.component'
// 授权登录
import { ShanDongTongLoginComponent } from './shandongtong-login/shandongtong-login.component'

const COMPONENTS = [MainComponent, UserLoginComponent, AuthLoginComponent, ShanDongTongLoginComponent];
const COMPONENTS_NOROUNT = [];

@NgModule({
  imports: [SharedModule, RouteRoutingModule, NzIconModule],
  declarations: [COMPONENTS, COMPONENTS_NOROUNT],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule {}
