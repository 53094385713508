import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

import { NoticeService } from '../services/notice.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // 60以内随机整数
  private random = parseInt((60 * Math.random()).toString(), 0);
  // 心跳检测随机时间 ms
  private intervalNum = this.random * 1000 + 60 * 1000 * 1;

  // 定时器对象
  private timeoutObj = null;
  private serverTimeoutObj = null;

  // websocket对象
  private ws: WebSocket = null;
  // 连接标识
  private lockReconnect = false;

  // websocket对象 服务器地址
  private wsServe = 'wss://file.leancloud.biz:4443/CNIC_FAndMServer';

  // websocket监听对象
  public websocketEventer = new EventEmitter<any>();

  constructor(private router: Router, private modal: NzModalService, private noticeServer: NoticeService) {}

  // 心跳检测 开始
  heartCheckStart() {
    this.timeoutObj = setTimeout(() => {
      if (this.ws.readyState === 1) {
        this.ws.send('ping');
        this.serverTimeoutObj = setTimeout(() => {
          this.ws.close();
          this.ws = null;
        }, this.intervalNum);
      } else {
        console.log('断开状态，尝试重连');
        this.reconnect();
      }
    }, this.intervalNum);
  }

  // 心跳检测 重置
  heartCheckReset() {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
  }

  // 创建websocket对象
  public creatWebsocket() {
    // 如果链接已经存在，则关闭并销毁对象
    if (this.ws != null) {
      this.ws.close();
      this.ws = null;
    }

    // 获取登录对象
    const loginInfo: any = window.sessionStorage.getItem('zw_loginInfo');
    const useruuid = JSON.parse(loginInfo).cUserUUID;
    const euuid = JSON.parse(loginInfo).account.cEUUID;
    const uuid = window.sessionStorage.getItem('SID');

    // 初始化链接对象
    this.ws = new WebSocket(this.wsServe + '/webSocketServer?cUserUUID=' + useruuid + ':' + euuid + ':' + uuid + ':06');

    // 链接成功回调
    this.ws.onopen = (event: any) => {
      // 重置心跳链接
      this.heartCheckReset();
      this.heartCheckStart();
      this.onOpen(event);
    };
    // 消息接收回调
    this.ws.onmessage = (event: any) => {
      // 重置心跳链接
      this.heartCheckReset();
      this.heartCheckStart();
      this.onMessage(event);
    };
    // 链接失败回调
    this.ws.onerror = (event: any) => {
      // 重连
      this.reconnect();
      this.onError(event);
    };
    // 链接关闭回调
    this.ws.onclose = (event: any) => {
      if (this.checkLogin()) {
        this.reconnect();
      } else {
        this.heartCheckReset();
      }
      this.onClose(event);
    };
  }

  // 销毁websocket对象
  public destroyWebsocket() {
    this.ws.close();
    this.ws = null;
    this.heartCheckReset();
  }

  // 重连websocket对象
  reconnect() {
    if (this.lockReconnect) {
      return;
    }
    if (!this.checkLogin()) {
      return;
    }

    this.lockReconnect = true;

    setTimeout(() => {
      this.creatWebsocket();
      this.lockReconnect = false;
    }, 6000);
  }

  // 检测当前用户登录状态
  checkLogin(): boolean {
    const loginInfo: any = window.sessionStorage.getItem('zw_loginInfo');
    if (
      loginInfo === undefined ||
      loginInfo === null ||
      loginInfo === '' ||
      JSON.parse(loginInfo).cUserUUID === undefined
    ) {
      return false;
    }
    return true;
  }

  // 监听回调
  onOpen(event: any) {}
  onError(event: any) {}
  onClose(event: any) {}
  onMessage(event: any) {
    // console.log(event);
    if (typeof event.data === 'string' && event.data === 'ping') {
      return;
    }

    const obj = JSON.parse(event.data);
    const cType = obj.cType;

    // 根据状态标识处理事件
    if (cType !== undefined && cType === -1) {
      this.clearUserInfo();
      this.modal.warning({
        nzTitle: '系统提示',
        nzContent: '您的账号已在另一地点登陆！',
        nzWrapClassName: 'vertical-center-modal',
        nzOnOk: () => {
          this.modal.closeAll();
          this.router.navigateByUrl('/login');
        },
      });
    } else if (cType !== undefined && cType === -2) {
      this.clearUserInfo();
      this.modal.info({
        nzTitle: '系统提示',
        nzContent: '当前用户被管理员清退！',
        nzWrapClassName: 'vertical-center-modal',
        nzOnOk: () => {
          this.modal.closeAll();
          this.router.navigateByUrl('/login');
        },
      });
    } else if (cType !== undefined && cType === -3) {
      this.clearUserInfo();
      this.modal.error({
        nzTitle: '系统提示',
        nzContent: '与服务器失去连接！',
        nzWrapClassName: 'vertical-center-modal',
        nzOnOk: () => {
          this.modal.closeAll();
          this.router.navigateByUrl('/login');
        },
      });
    } else {
      // 添加一条消息
      this.noticeServer.addNotice(obj);
    }
  }

  // 清除登录用户信息
  clearUserInfo() {
    window.sessionStorage.removeItem('zw_menuInfo');
    window.sessionStorage.removeItem('zw_currentOrg');
    window.sessionStorage.removeItem('zw_loginInfo');
    window.sessionStorage.removeItem('SID');
  }
}
