import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxTinymceModule } from 'ngx-tinymce';
// Delon
import { AlainThemeModule } from '@delon/theme';
import { DelonABCModule } from '@delon/abc';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
import { DelonChartModule } from '@delon/chart';

// #region third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';

// modified
import { zwTableComponent } from './zw-table';
import { zwExcelComponent } from './zw-excel';
import { YhFlowComponent } from './zw-flow';


const THIRDMODULES = [NgZorroAntdModule, CountdownModule, NgxTinymceModule];
// #endregion

// #region your componets & directives
const COMPONENTS = [zwTableComponent, zwExcelComponent, YhFlowComponent];
const DIRECTIVES = [];
// #endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonACLModule,
    DelonChartModule,
    DelonFormModule,
    // Third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // Your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonACLModule,
    DelonChartModule,
    DelonFormModule,
    // Third libs
    ...THIRDMODULES,
    // Your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  entryComponents:[...COMPONENTS]
})
export class SharedModule {}
