import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { YhFlowService } from 'src/app/services/yhFlow.service';

@Component({
    selector: 'yh-flow-component',
    template: `
    <iframe [src]="realUrl" id="process_iframe_{{cMenuCode}}" frameborder="0" width="100%" height="100%"></iframe>
    <div class="footer">
      <button type="button" (click)="close()" class="ant-btn" style="margin-right: 8px;"><span>关闭</span></button>
      <button type="button" (click)="print()" class="ant-btn ant-btn-primary"><span>打印</span></button>
    </div>
    `,
    styles: [
      `
        .footer {
          position: absolute;
          bottom: 0px;
          width: 100%;
          border-top: 1px solid rgb(232, 232, 232);
          padding: 10px 16px;
          text-align: right;
          left: 0px;
          background: #fff;
        }
        .toper {
          position: absolute;
          top: 0px;
          width: 100%;
          border-top: 1px solid rgb(232, 232, 232);
          padding: 10px 16px;
          text-align: right;
          left: 0px;
          background: #fff;
        }
      `
    ]
  })
  export class YhFlowComponent {
  
    @Input() url: string; // 流程url
    @Input() cMenuCode: string; // 菜单编码
    @Input() ids:Array<string>;
    realUrl: any;// 安全的url 
    showFlowBtn: boolean =  false;// 是否显示流程按钮
  
    constructor(
      private domSanitizer:DomSanitizer,
      private yhFlowService:YhFlowService
    ) {}

    ngOnInit(): void {
      this.realUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url)
    }

    // 获取安全的url [src]="getUrl()" src="http://192.168.16.167:8088/portal/r/w?sid=739142a8-0043-4e02-9ef4-93f2f88fe68a&cmd=CLIENT_BPM_FORM_MAIN_PAGE_OPEN&processInstId=284bc9af-06ad-4af8-abf9-2231b3a7b3e3&taskInstId=a2ff1774-c6fe-4572-a1a2-d03ab56c3f65&openState=1&displayToolbar=true"
    getUrl(){
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.url)
    }
  
    // 关闭
    close(){
      this.yhFlowService.closeFlowDraer();
      // this.yhFlowService.flowBtShowEventer.emit(true);
    }
  
    // 打印
    print(){
        this.yhFlowService.flowPrint(this.cMenuCode,this.ids);
    }
    
  }