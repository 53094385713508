import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';
import { WebSocketService } from '../services/websocket.service';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class OrgChooseService {
  private routerURL = '';
  private menuInfo: any;
  private targetObj: any = {};

  public orgChooseEventer = new EventEmitter<any>();
  public orgLoadEventer = new EventEmitter<any>();

  constructor(private http: HttpClient, private router: Router, private modal: NzModalService, private webSocketService: WebSocketService) {}

  // 监听路由返回菜单code和id
  public getMenuInfo(fun?) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log(event);

        // 获取当前路由地址
        this.routerURL = event.url;
        // 获取当前授权的所有菜单数据
        this.menuInfo = JSON.parse(window.sessionStorage.getItem('zw_menuInfo'));
        this.targetObj = {};
        this.getCodeID(this.menuInfo);
        setTimeout(() => {
          fun(this.targetObj);
        }, 0);
      }
    });
  }

  // 递归比对 获取菜单code和id
  public getCodeID(menuObj: any) {
    if(menuObj === null){return;}
    for (const obj of menuObj) {
      if (obj.children !== undefined && obj.children.length > 0) {
        this.getCodeID(obj.children);
      } else {
        if (obj.link === this.routerURL) {
          this.targetObj.cMenuCode = obj.cMenuCode;
          this.targetObj.cMenuName = obj.text;
          this.targetObj.iMenuID = obj.iMenuID;
          return;
        }
      }
    }
  }

  // 通过菜单code和id获取组织信息
  public getOrgListInfo(menuCode: string, menuID: number, success?, error?) {
    const loginInfo: any = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    const map = {
      bManage: 0,
      bLeaseAdmin: loginInfo.bLeaseAdmin,
      cAccUniqueID: loginInfo.account.cAccUniqueID,
      iSysID: loginInfo.account.iSysID,
      cMenuCode: menuCode,
      iMenuID: menuID,
      iType: 1,
	  iUserID: loginInfo.iUserID,
    };

    // 调用登录服务api
    this.http.post('/cnic-auth/ZwzqAction/getShowOrgs', map).subscribe(
      (res: any) => {
        if (res.code === 3000){
          this.webSocketService.clearUserInfo();
          this.modal.error({
            nzTitle: '系统提示',
            nzContent: '帐号登录超时，请重新登录！',
            nzWrapClassName: 'vertical-center-modal',
            nzOnOk: () => {
              this.modal.closeAll();
              this.router.navigateByUrl('/login');
            },
          });
          return;
        }
        window.sessionStorage.setItem('zw_OrgList', JSON.stringify(res.data));
        success(res);
      },
      (err: any) => {
        error(err);
      },
    );
  }

  // 设置并存储当前组织对象
  public setCurrentOrg(orgObj: any) {
    const currentOrg: any = {
      cOrgCode: orgObj.cOrgCode,
      cOrgName: orgObj.cOrgName,
      cOrgUUID: orgObj.cOrgUUID,
      cShowTitle: orgObj.cShowTitle,
      iOrgID: orgObj.iOrgID,
      cPrincipal:orgObj.cPrincipal,
    };

    // tslint:disable-next-line: prefer-conditional-expression
    if (orgObj.bIsGroupData === undefined) {
      currentOrg.bIsGroupData = false;
    } else {
      currentOrg.bIsGroupData = orgObj.bIsGroupData;
    }

    window.sessionStorage.setItem('zw_currentOrg', JSON.stringify(currentOrg));
  }

  // 获取当前组织对象
  public getCurrentOrg() {
    return JSON.parse(window.sessionStorage.getItem('zw_currentOrg'));
  }
}
