import { _HttpClient, TitleService } from '@delon/theme';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '@delon/cache';
import { ToolsService } from '../../services/tools.service';
import { YhFlowService } from 'src/app/services/yhFlow.service';

declare var $: any;

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class UserLoginComponent implements OnInit {
  // logo
  logo: any;
  // 登录背景图
  loginBackground: any;
  // 系统标题
  sysTitle: string;

  // UUID (token)
  UUID: string;
  tmpSinID = '';
  // 校验码
  captchaImg: any;

  // 表单对象
  formObj: FormGroup;

  // 登录加载状态标识
  isLoading: boolean;

  constructor(
    public http: _HttpClient,
    private modalSrv: NzModalService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private eleRef: ElementRef,
    private message: NzMessageService,
    private cache: CacheService,    
    private toolsService: ToolsService,
    private titleSrv: TitleService,
    private activatedRoute: ActivatedRoute,
    private yhFlowService: YhFlowService
  ) {
    // 移除所有模态窗体
    this.modalSrv.closeAll();
    // 初始化formGroup实例
    this.formObj = new FormGroup(
      {
        SinID: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
        }),
        UserName: new FormControl(null, {
          validators: Validators.required,
        }),
        PassWord: new FormControl(null, {
          validators: Validators.required,
        }),
        Captcha: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
        }),
      },
      {
        updateOn: 'blur',
      },
    );
    let defaultSinID = '';
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.code !== undefined && queryParams.code !== null) {
        defaultSinID = queryParams.code;
      } else if (window.localStorage.getItem('SinID')) {
        defaultSinID = window.localStorage.getItem('SinID');
      }

      if (defaultSinID.trim() !== '') {
        this.tmpSinID = defaultSinID.trim();
      }
    });
    // 初始化登录页面基础设置
    this.initLoginSetInfo();
  }

  ngOnInit() {
    if (window.sessionStorage.getItem('zw_code')) {
      this.formObj.patchValue({
        SinID: window.sessionStorage.getItem('zw_code'),
      });
    }
    this.creatCaptcha();
    this.isLoading = false;
  }

  // 生成UUID
  getUUID() {
    return (
      this.randomStr() +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      '-' +
      this.randomStr() +
      this.randomStr() +
      this.randomStr()
    );
  }
  // 创建随机字符串
  randomStr() {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  // 创建验证码
  creatCaptcha() {
    // 生成一次UUID
    this.UUID = this.getUUID();
    // 请求一次验证码
    this.http.get('/cnic-auth/BaseManageAction/getVerifyCode/' + this.UUID).subscribe((res: any) => {
      // tslint:disable-next-line: prefer-conditional-expression
      if (res.code === 1) {
        this.captchaImg = this.sanitizer.bypassSecurityTrustResourceUrl(res.data.imgStr);
      } else {
        this.captchaImg = null;
      }
    });
  }

  // 获取表单数据 - 识别码
  get SinID() {
    return this.formObj.controls.SinID;
  }
  // 获取表单数据 - 用户名
  get UserName() {
    return this.formObj.controls.UserName;
  }
  // 获取表单数据 - 登录密码
  get PassWord() {
    return this.formObj.controls.PassWord;
  }
  // 获取表单数据 - 校验码
  get Captcha() {
    return this.formObj.controls.Captcha;
  }

  // 初始化页面设置
  initLoginSetInfo() {
    // 获取基础设置数据
    if (this.cache.has('zw_setInfo')) {
      const setInfo: any = JSON.parse(this.cache.get('zw_setInfo', { mode: 'none', type: 'm' }));

      this.sysTitle = setInfo.title;
      setTimeout(() => {
        this.titleSrv.setTitle(this.sysTitle);
      }, 500);

      this.loginBackground = this.sanitizer.bypassSecurityTrustStyle(
        'background-image : url(' + setInfo.loginBackground + ')',
      );

      // tslint:disable-next-line: prefer-conditional-expression
      if (setInfo.default) {
        this.logo = this.sanitizer.bypassSecurityTrustUrl('../../../' + setInfo.logo);
      } else {
        this.logo = this.sanitizer.bypassSecurityTrustUrl(setInfo.logo);
      }
    } else {
      this.sysTitle = '奥链·政企协同平台';
      setTimeout(() => {
        this.titleSrv.setTitle(this.sysTitle);
      }, 2000);
      this.logo = this.sanitizer.bypassSecurityTrustUrl('../../../assets/img/logo.png');
      this.loginBackground = this.sanitizer.bypassSecurityTrustStyle(
        'background-image : url(assets/img/login_backgroud.jpg)',
      );
    }
  }

  // 表单输入框 回车事件
  enterEvent(event: any, flag: number) {
    if (event.keyCode === 13) {
      if (flag === 0) {
        // 识别码
        this.eleRef.nativeElement.querySelector('#SinID').blur();
        this.SinID.markAsDirty();
        this.SinID.updateValueAndValidity();
        if (this.SinID.invalid) {
          return;
        }

        this.eleRef.nativeElement.querySelector('#UserName').focus();
      }

      if (flag === 1) {
        // 登录账户
        this.eleRef.nativeElement.querySelector('#UserName').blur();
        this.UserName.markAsDirty();
        this.UserName.updateValueAndValidity();
        if (this.UserName.invalid) {
          return;
        }

        this.eleRef.nativeElement.querySelector('#PassWord').focus();
      }

      if (flag === 2) {
        // 登录密码
        this.eleRef.nativeElement.querySelector('#PassWord').blur();
        this.PassWord.markAsDirty();
        this.PassWord.updateValueAndValidity();
        if (this.PassWord.invalid) {
          return;
        }

        this.eleRef.nativeElement.querySelector('#Captcha').focus();
      }

      if (flag === 3) {
        // 验证码
        this.eleRef.nativeElement.querySelector('#Captcha').blur();
        this.Captcha.markAsDirty();
        this.Captcha.updateValueAndValidity();
        if (this.Captcha.invalid) {
          return;
        }

        setTimeout(() => {
          this.login();
        }, 100);
      }
    }
  }

  // 登录按钮点击事件
  loginButtonEvent() {
    this.eleRef.nativeElement.querySelector('#Captcha').blur();
    setTimeout(() => {
      this.login();
    }, 100);
  }

  // 登录操作
  login() {
    this.cache.clear();
    // 校验是否正在登录
    if (this.isLoading) {
      return;
    }

    // 录入校验 -- 识别码
    this.SinID.markAsDirty();
    this.SinID.updateValueAndValidity();
    if (this.SinID.invalid) {
      return;
    }

    // 录入校验 -- 登录账户
    this.UserName.markAsDirty();
    this.UserName.updateValueAndValidity();
    if (this.UserName.invalid) {
      return;
    }

    // 录入校验 -- 登录密码
    this.PassWord.markAsDirty();
    this.PassWord.updateValueAndValidity();
    if (this.PassWord.invalid) {
      return;
    }

    // 录入校验 -- 校验码
    this.Captcha.markAsDirty();
    this.Captcha.updateValueAndValidity();
    if (this.Captcha.invalid) {
      return;
    }

    // 登录操作
    this.isLoading = true;
    // const preUrl = 'http://192.168.11.61:18081/';
    const preUrl = '';
    this.http.post(`${preUrl}/cnic-auth/ZwzqAction/doLoginZw`, {
      cCNICSigID: this.SinID.value,
      cUserName: this.toolsService.encrypt(this.UserName.value),
      cPsd: this.toolsService.encrypt(this.PassWord.value),
      cUUID: this.UUID,
      cYZM: this.Captcha.value,
      bEncrypt: true,
      iType: 1,
      // cUserUUID: JSON.parse(window.sessionStorage.getItem('zw_loginInfo')).cUserUUID,
    })
    .subscribe(
      (res: any) => {
        // console.warn(res);
        this.isLoading = false;

        if (res.code === 1) {
          const user = res.data;

          // 判定用戶是否其他PC端登录
          if(user.bAlreadyLogin){
            this.modalSrv.confirm({
              nzTitle: '系统提示',
              nzContent: '当前账户已在别处登录，是否强制登录?',
              nzWrapClassName: 'vertical-center-modal',
              nzOnOk: () => {
                this.modalSrv.closeAll();
                this.forcedToLogin (user.sys_User, (fuser: any)=>{
                  this.loginInfoExe(fuser);
                })
              }
            });
          }
          else{
            this.loginInfoExe(user);
          }        
        }
        else {
          this.message.error(res.msg);
          // 如果验证码过期，则重新初始化一个
          //if (res.code === 2000) {
            this.creatCaptcha();
          //}
        }
      },
      (error: any) => {
        this.isLoading = false;
        this.message.error('连接服务器失败...');
        this.creatCaptcha();
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  // 强制登录操作
  forcedToLogin(param: any, nextFun){   
    this.isLoading = true;

    this.http.post("/cnic-auth/ZwzqAction/getDataNoLogin",{
      cUserUUID : param.cUserUUID,
      cUserName : param.cUserName,
      cLoginDate : this.toolsService.getNowFormatDateYMD(),
      cCNICSigID : param.cCNICSigID,
      cUUID : this.UUID,
      iType : 1,      
      isLogin : true
    })
    .subscribe(
      (res: any) => {
          if (res.code === 1) {
            this.isLoading = true;
            if(nextFun){
                nextFun(res.data);
            }
          }
          else {
            this.message.error(res.msg);
          }
      },
      (error: any) => {
        this.isLoading = false;
        this.message.error('连接服务器失败...');
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  // 处理用户登录信息
  loginInfoExe(res: any){
    // 处理登录信息
    this.toolsService.loginInfoHandle(res, this.SinID.value,
      () => {
        // 保存UUID
        window.sessionStorage.setItem('SID', this.UUID);
        // 保存登录类
        window.sessionStorage.setItem('loginType', 'gov');
        // 初始化FBI
        this.toolsService.initFBI(res);

        // 跳转工作台首页
        this.router.navigateByUrl('/');
        // 判断密码强度
        setTimeout(() => {
          res && res.ispass ? this.pswWaring(res) : null;    
        },1000)
      });
  }

  // Adrian, 2021_06_15, ************************************************************
  // 密码强度不足警告
  pswWaring(res: any) {
    this.modalSrv.confirm({
      nzTitle: '系统提示',
      nzContent: `当前账户密码强度不足。\n\r所选密码策略为：${res.cPassStrongName}。`,
      nzWrapClassName: 'vertical-center-modal',
      nzOkText: '前往修改',
      nzOnOk: () => {
        this.modalSrv.closeAll();
        this.router.navigate(['SysManage/updatePasswd'], {
          queryParams: {
            stra:  res && res.cPassStrongName ? res.cPassStrongName : null
          }
        });
      },
      nzCancelText: '下次再说',
      nzOnCancel: () => {
        this.modalSrv.closeAll();
      }
    });
  }
}
