import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeIconList, NoticeItem } from '@delon/abc/notice-icon';
import { NzConfigService } from 'ng-zorro-antd/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { NoticeService } from '../../../../services/notice.service';

declare var $: any;

@Component({
  selector: 'header-notify',
  template: `
    <notice-icon
      id="noticeButton"
      [data]="data"
      [count]="noticeCount"
      btnClass="alain-default__nav-item"
      btnIconClass="alain-default__nav-item-icon"
      (select)="noticeSelect($event)"
      (clear)="noticeClear()"
      (popoverVisibleChange)="noticePopover()"
    ></notice-icon>

    <ng-template #noticeTemplate let-notification>
      <div class="ant-notification-notice-content">
        <div class="ant-notification-notice-with-icon">
          <span class="ant-notification-notice-icon"><i nz-icon nzType="message" style="color:#536ec2"></i></span>
          <div class="ant-notification-notice-message">{{ newNotice.cMsgTitle }}</div>
          <div class="ant-notification-notice-description">{{ newNotice.cMsgBody }}</div>
          <span *ngIf="newNotice.cMsgType === '11'" class="ant-notification-notice-btn">
            <button nz-button nzType="primary" nzSize="small" (click)="newNoticeClick(newNotice)">
              <span>查 看</span>
            </button>
          </span>
        </div>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNotifyComponent implements OnInit {
  // 消息体对象   list：未读消息集合
  data: NoticeItem[] = [
    {
      title: '',
      list: [],
      emptyText: '你已查看所有通知',
      emptyImage: 'assets/img/empty_notice.svg',
      clearText: '忽略全部通知',
    },
  ];

  // 未读消息数量
  noticeCount = 0;

  // 新消息显示对象
  newNotice: any;

  // 测试用消息对象
  // noticeArray = [
  //   {
  //     iMsgID: '001',
  //     cMsgTitle: '@ViewChild、@ViewChildren获取子元素',
  //     dSendSysTimeLong: 1577266358223,
  //   },
  //   {
  //     iMsgID: '002',
  //     cMsgTitle: 'exportAs属性很关键',
  //     dSendSysTimeLong: 1577266358223,
  //     currentInfoDto: {
  //       cEUUID: 'zgxr45207cdb99074d2cbfb34f8786e703d4',
  //     },
  //   },
  //   {
  //     iMsgID: '003',
  //     cMsgTitle: '模板变量名等号右边的就是TestDirective指令exportAs对应的名字',
  //     dSendSysTimeLong: 1577266358223,
  //   },
  // ];

  @ViewChild(TemplateRef, { static: false }) noticeTemplate: TemplateRef<{}>;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private messageServe: NzMessageService,
    private config: NzConfigService,
    private notification: NzNotificationService,
    private noticeServe: NoticeService,
  ) {
    // 初始化消息变化监听服务 新增、删除
    this.noticeServe.noticeChangeEvent.subscribe((noticeList: any) => {
      this.noticeCount = noticeList.length;
      this.data = this.noticeUpdate(noticeList);

      // 触发dom渲染
      this.cdr.detectChanges();
    });

    // 初始化新消息监听服务
    this.noticeServe.noticeAddEvent.subscribe((notice: any) => {
      this.notification.remove();
      setTimeout(() => {
        this.newNotice = notice;
        this.notification.template(this.noticeTemplate);
      }, 100);
    });

    // 设置消息相关全局参数
    this.config.set('notification', { nzTop: 74 });
  }

  ngOnInit() {
    const userInfo: any = JSON.parse(window.sessionStorage.getItem('zw_loginInfo'));
    const useruuid = userInfo.cUserUUID;

    // 初始化未读消息
    //this.noticeServe.getNoticeNoRead(useruuid, '06', 0, res => {
      //if (res.code === 1) {
        //this.noticeServe.initNotice(res.data);

        //// 模拟添加消息
        //// this.noticeServe.addNotice(this.noticeArray[0]);
        //// setTimeout(() => {
        ////   this.noticeServe.addNotice(this.noticeArray[1]);
        //// }, 4000);
      //}
   // });

    this.noticeServe.getMsgList(1, '06', '01',null, (res) => {
      if (res.code === 1) {
        //
        this.noticeServe.initNotice(res.data.results);
      }
    });
  }

  // 消息列表展示
  noticePopover() {
    setTimeout(() => {
      // 初始化弹窗样式
      $('.header-dropdown.notice-icon')
        .find('.ant-tabs-nav-container')
        .css('display', 'none');
    }, 100);
  }
  // 更新消息列表数据
  noticeUpdate(notices: NoticeIconList[]): NoticeItem[] {
    const data = this.data.slice();
    data.forEach(i => (i.list = []));

    notices.forEach(item => {
      data[0].list.push(item);
    });
    return data;
  }

  // 忽略全部消息
  noticeClear() {
    // 隐藏窗体
    $('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing').trigger('click');

    // 将消息设置为已读
    let msgidArray = [];
    let ids = [];
    for (const obj of this.data[0].list) {
      msgidArray.push(obj.iMsgID);
    }

    for (const obj of this.data[0].list) {
      ids.push(obj.iMsgReceiverID);
    }
    this.noticeServe.setNoticeRead(ids.join(','));
    // 删除消息列表
    this.noticeServe.deleteNotice(msgidArray.join(','));
  }

  // 消息列表项点击
  noticeSelect(obj: any) {
    // 隐藏窗体
    $('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing').trigger('click');
    // 处理消息
    this.handleNoticeClickEvent(obj.item);
  }

  // 新消息提醒窗点击
  newNoticeClick(notice: any) {
    // 关闭窗体
    this.notification.remove();
    // 处理消息
    this.handleNoticeClickEvent(notice);
  }

  // 消息点击事件处理
  handleNoticeClickEvent(notice: any) {
    if (notice.cMsgType === undefined || notice.cMsgType === null || notice.cMsgType === '') {
      this.messageServe.error('消息类型无法识别');
      return;
    }

    // 将消息设置为已读
    this.noticeServe.setNoticeRead(notice.iMsgReceiverID);
    // 将消息从列表中删除
    this.noticeServe.deleteNotice(notice.iMsgID);

    // 根据消息类型进行处理 10：普通内容型  11：业务跳转型
    if (notice.cMsgType === '10') {
    }
    if (notice.cMsgType === '11') {
      let cExtrasparam = notice.cExtrasparam;
      cExtrasparam = cExtrasparam.replace(/\"\{/g, '{');
      cExtrasparam = cExtrasparam.replace(/\}\"/g, '}');
      cExtrasparam = cExtrasparam.replace(/\\/g, '');
      let param = JSON.parse(cExtrasparam);
      this.router.navigate([param.cOpenMenuURL],{ queryParams: param.cParamJsonString });
      this.noticeServe.appealModal.emit(param.cParamJsonString);
      // this.noticeServe.appealModal.emit(!!notice.cParamJsonString ? JSON.parse(notice.cParamJsonString) : param.cParamJsonString);
      // let queryParams = !!notice.cParamJsonString ? JSON.parse(notice.cParamJsonString) : (!!param.cParamJsonString ? JSON.parse(param.cParamJsonString) : {})
      // this.router.navigate([param.cOpenMenuURL],{ queryParams });
      // this.noticeServe.appealModal.emit(JSON.parse(notice.cParamJsonString));
    }
  }
}
